<template>
  <div>
    <b-card-code :title="$t('emmaTable')">
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="emmaList"

        :select-options="{
          enabled: false,
        }"

        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <div
            v-if="props.column.field === 'displayOrganizationName'"
          >
            <b-button
              variant="outline-secondary"
              block
              @click="$router.push('/dashboard/organization?id=' + props.row.organizationId)"
            >
              {{ props.row.displayOrganizationName }}
            </b-button>
          </div>

          <span v-else-if="props.column.field === 'displaySuborganizationName'">
            <b-button
              v-if="props.row.displaySuborganizationName !== ''"
              variant="outline-secondary"
              block
              @click="$router.push('/dashboard/suborganization?id=' + props.row.suborganizationId)"
            >
              {{ props.row.displaySuborganizationName }}
            </b-button>
            <b-button
              v-else
              variant="outline-danger"
              block
              disabled
            >
              {{ $t('noCounty') }}
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'displayClusterName'">
            <b-button
              v-if="props.row.displayClusterName !== ''"
              variant="outline-secondary"
              block
              @click="$router.push('/dashboard/cluster?id=' + props.row.clusterId)"
            >
              {{ props.row.displayClusterName }}
            </b-button>
            <b-button
              v-else
              variant="outline-danger"
              block
              disabled
            >
              {{ $t('noHouse') }}
            </b-button>
          </span>

          <span
            v-else-if="props.column.field === 'displayLocalGroupName'"
          >
            <b-button
              v-if="props.row.displayLocalGroupName !== ''"
              variant="outline-secondary"
              block
              @click="$router.push('/dashboard/localGroup?id=' + props.row.localGroupId)"
            >
              {{ props.row.displayLocalGroupName }}
            </b-button>
            <b-button
              v-else
              variant="outline-danger"
              block
              disabled
            >
              {{ $t('noFlat') }}
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'serial'">
            <div :id="'serial-' + props.row.serial">
              <b-button
                variant="outline-secondary"
                block
                @click="$router.push('/dashboard/emma?serial=' + props.row.serial)"
              >
                <span>
                  <span class="font-weight-bolder">
                    {{ props.row.serial }}
                  </span>
                  <feather-icon
                    v-if="props.row.deepSleep"
                    class="text-danger"
                    icon="AlertOctagonIcon"
                  />
                </span>
              </b-button>
              <b-tooltip
                v-if="props.row.deepSleep"
                triggers="hover"
                variant="danger"
                :target="'serial-' + props.row.serial"
                :title="$t('deviceInDeepSleep')"
                :delay="{ show: 0, hide: 50 }"
              />
            </div>
          </span>

          <span
            v-else-if="props.column.field === 'timestamp'"
            :key="updateKey"
          >
            <span>
              <b-button
                class="match-height"
                :variant="(getTimeSinceHeartbeat(props.row.timestamp) < $store.state.emmas.secondsBeforeOffline) ? 'success' : 'danger'"
                block
                disabled
              >
                <div v-if="getTimeSinceHeartbeat(props.row.timestamp) < $store.state.emmas.secondsBeforeOffline">
                  {{ $t('online') }}
                </div>
                <div v-else-if="props.row.timestamp">
                  {{ $t('offline2', {secondsToDhms: secondsToDhms(getTimeSinceHeartbeat(props.row.timestamp))}) }}
                </div>
                <div v-else>
                  {{ $t('offline') }}
                </div>
              </b-button>
            </span>
          </span>

          <!-- Column: activeNetworkInterface -->
          <span v-else-if="props.column.field === 'activeNetworkInterface'">
            <div :id="'active-network-' + props.row.serial">

              <b-button
                id="activeNetworkInterfaceButton"
                disabled
                block
                :variant="(props.row.activeNetworkInterface && (getTimeSinceHeartbeat(props.row.timestamp) < $store.state.emmas.secondsBeforeOffline)) ? 'success' : 'danger'"
              >
                <span>
                  <span class="font-weight-bolder">
                    {{ getNetworkText(props.row.activeNetworkInterface) }}
                  </span>
                  <feather-icon
                    :icon="getNetworkIcon(props.row.activeNetworkInterface)"
                  />
                </span>
              </b-button>
            </div>
            <b-tooltip
              triggers="hover"
              :target="'active-network-' + props.row.serial"
              :title="getTooltipText(props.row.activeNetworkInterface)"
              :delay="{ show: 500, hide: 50 }"
            />
          </span>

          <!-- Column: activeNetworkInterface -->
          <span v-else-if="props.column.field === 'edit'">
            <span>
              <b-dropdown
                :disabled="getTimeSinceHeartbeat(props.row.timestamp) > $store.state.emmas.secondsBeforeOffline"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-b-modal.edit-emma-modal
                  @click="selectedEmma = props.row"
                >
                  <feather-icon
                    icon="MoveIcon"
                    class="mr-50"
                  />
                  <span>{{ $t('move') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t('showing1To') }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','25','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> {{ $t('ofEntries', {total: props.total}) }} </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-code>
    <edit-emma-modal
      v-if="emmas.length !== 0"
      :emma="selectedEmma"
    />
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BPagination, BFormSelect, BButton, BTooltip, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import EditEmmaModal from '@/components/forms/EditEmmaModal.vue'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BButton,
    BPagination,
    BFormSelect,
    BDropdown,
    BTooltip,
    BDropdownItem,
    EditEmmaModal,
  },

  props: {
    emmas: {
      type: Array,
      required: true,
    },
    showAllColumns: {
      type: Boolean,
      default: false,
    },
    showOrganisations: {
      type: Boolean,
      default: false,
    },
    showSuborganisations: {
      type: Boolean,
      default: false,
    },
    showClusters: {
      type: Boolean,
      default: false,
    },
    showLocalGroups: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterTextOrganization: '',
      filterTextSuborganization: '',
      filterTextCluster: '',
      filterTextLocalGroup: '',
      selectedEmma: this.emmas[0],
      autoUpdate: undefined,
      autoFetchTimestamps: undefined,
      currentTimestamp: Date.now(),
      updateKey: 0,
      pageLength: 25,
      dir: false,
    }
  },
  computed: {
    columns() {
      const customColumns = [
        {
          label: this.$t('serialNumber'),
          field: 'serial',
          width: '180px',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('filter'),
          },
        },
        {
          label: this.$t('network'),
          field: 'activeNetworkInterface',
          width: '130px',
          sortable: false,
          filterOptions: {
            enabled: true,
            filterDropdownItems: [
              { value: 'wifi0', text: this.$t('wifi') },
              { value: 'eth0', text: this.$t('lan') },
              { value: 'ppp0', text: this.$t('gsm') },
            ],
            placeholder: this.$t('all'),
          },
        },
        {
          label: this.$t('lastHeartbeat'),
          field: 'timestamp',
          width: '250px',
          sortFn: this.sortHeartbeat,
          filterOptions: {
            enabled: true,
            filterDropdownItems: [
              { value: 'online', text: this.$t('online') },
              { value: 'offline', text: this.$t('offline') },
            ],
            filterFn: this.filterStatus,
            placeholder: this.$t('all'),
          },
        },
        {
          label: '',
          field: 'edit',
          sortable: false,
        },
      ]

      if (this.showAllColumns || this.showLocalGroups) {
        customColumns.unshift(
          {
            label: this.$t('flat'),
            field: 'displayLocalGroupName',
            sortFn: this.sortAlphabetically,
            filterOptions: {
              enabled: true,
              placeholder: this.$t('filter'),
            },
          },
        )
      }

      if (this.showAllColumns || this.showClusters) {
        customColumns.unshift(
          {
            label: this.$t('house'),
            field: 'displayClusterName',
            sortFn: this.sortAlphabetically,
            filterOptions: {
              enabled: true,
              placeholder: this.$t('filter'),
            },
          },
        )
      }

      if (this.showAllColumns || this.showSuborganisations) {
        customColumns.unshift(
          {
            label: this.$t('county'),
            field: 'displaySuborganizationName',
            sortFn: this.sortAlphabetically,
            filterOptions: {
              enabled: true,
              placeholder: this.$t('filter'),
            },
          },
        )
      }

      if (this.showAllColumns || this.showOrganisations) {
        customColumns.unshift({
          label: this.$t('organisation'),
          field: 'displayOrganizationName',
          sortFn: this.sortAlphabetically,
          filterOptions: {
            enabled: true,
            placeholder: this.$t('filter'),
          },
        })
      }

      return customColumns
    },
    emmaList() {
      const filteredEmmas = JSON.parse(JSON.stringify(this.emmas))

      for (let i = 0; i < filteredEmmas.length; i += 1) {
        filteredEmmas[i].displayOrganizationName = this.getEmmaOrg(filteredEmmas[i])
        filteredEmmas[i].displaySuborganizationName = this.getEmmaSuborganization(filteredEmmas[i])
        filteredEmmas[i].displayClusterName = this.getEmmaCluster(filteredEmmas[i])
        filteredEmmas[i].displayLocalGroupName = this.getEmmaLocalGroup(filteredEmmas[i])
      }

      return filteredEmmas
    },
  },
  beforeDestroy() {
    clearInterval(this.autoUpdate)
    clearInterval(this.autoFetchTimestamps)
  },
  mounted() {
    this.$store.dispatch('emmas/updateEmmaHeartbeats')
    this.autoUpdate = setInterval(this.setUpdateKey, 1000)
    this.autoFetchTimestamps = setInterval(this.updateEmmaTimestamps, 15000)
  },
  methods: {
    filterStatus(data, filterString) {
      console.log(data)
      if (filterString === 'all') {
        return true
      }
      if (filterString === 'offline') {
        return this.getTimeSinceHeartbeat(data) > this.$store.state.emmas.secondsBeforeOffline
      }
      if (filterString === 'online') {
        return this.getTimeSinceHeartbeat(data) <= this.$store.state.emmas.secondsBeforeOffline
      }
      return true
    },
    async setUpdateKey() {
      this.currentTimestamp = Date.now()
      this.updateKey = Math.ceil(Math.random() * 1000)
    },
    async updateEmmaTimestamps() {
      await this.$store.dispatch('emmas/updateEmmaHeartbeats')
    },
    secondsToDhms(seconds) {
      const d = Math.floor(seconds / (3600 * 24))
      const h = Math.floor((seconds % (3600 * 24)) / 3600)
      const m = Math.floor((seconds % 3600) / 60)
      const s = Math.floor(seconds % 60)

      if (d === 0 && h === 0 && m === 0 && s === 0) {
        return `0 ${this.$t('seconds')}`
      }

      const dDisplay = d > 0 ? d + (d === 1 ? ` ${this.$t('day')} ` : ` ${this.$t('days')} `) : ''
      const hDisplay = h > 0 ? h + (h === 1 ? ` ${this.$t('hour')} ` : ` ${this.$t('hours')} `) : ''
      const mDisplay = m > 0 ? m + (m === 1 ? ` ${this.$t('minute')} ` : ` ${this.$t('minutes')} `) : ''
      const sDisplay = s > 0 ? s + (s === 1 ? ` ${this.$t('second')}` : ` ${this.$t('seconds')} `) : ''

      if (dDisplay !== '') {
        return dDisplay + hDisplay
      }
      if (hDisplay !== '') {
        return hDisplay + mDisplay
      }
      if (mDisplay !== '' || sDisplay !== '') {
        return mDisplay + sDisplay
      }
      if (seconds === -1) {
        return `0 ${this.$t('seconds')}`
      }
      return this.$t('noHeartbeatFound')
    },
    getEmmaLocalGroup(emma) {
      const singleLocalGroup = this.$store.state.localGroups.localGroups.find(localGroup => localGroup.id === emma.localGroupId)
      if (singleLocalGroup !== undefined) {
        return singleLocalGroup.name
      }
      return ''
    },
    getEmmaCluster(emma) {
      const singleCluster = this.$store.state.clusters.clusters.find(cluster => cluster.id === emma.clusterId)
      if (singleCluster !== undefined) {
        return singleCluster.name
      }
      return ''
    },
    getEmmaSuborganization(emma) {
      const singleSuborganization = this.$store.state.suborganizations.suborganizations.find(suborganization => suborganization.id === emma.suborganizationId)
      if (singleSuborganization !== undefined) {
        return singleSuborganization.name
      }
      return ''
    },
    getEmmaOrg(emma) {
      const singleOrganization = this.$store.state.organizations.organizations.find(organization => organization.id === emma.organizationId)
      if (singleOrganization !== undefined) {
        return singleOrganization.name
      }
      return ''
    },
    sortAlphabetically(x, y, col, rowX, rowY) {
      if (col.field === 'displayOrganizationName') {
        if (this.getEmmaOrg(rowX) < this.getEmmaOrg(rowY)) { return -1 }
        if (this.getEmmaOrg(rowX) > this.getEmmaOrg(rowY)) { return 1 }
      }
      if (col.field === 'displaySuborganizationName') {
        if (this.getEmmaSuborganization(rowX) < this.getEmmaSuborganization(rowY)) { return -1 }
        if (this.getEmmaSuborganization(rowX) > this.getEmmaSuborganization(rowY)) { return 1 }
      }
      if (col.field === 'displayClusterName') {
        if (this.getEmmaCluster(rowX) < this.getEmmaCluster(rowY)) { return -1 }
        if (this.getEmmaCluster(rowX) > this.getEmmaCluster(rowY)) { return 1 }
      }
      if (col.field === 'displayLocalGroupName') {
        if (this.getEmmaLocalGroup(rowX) < this.getEmmaLocalGroup(rowY)) { return -1 }
        if (this.getEmmaLocalGroup(rowX) > this.getEmmaLocalGroup(rowY)) { return 1 }
      }
      return -1
    },
    sortHeartbeat(x, y, col, rowX, rowY) {
      const heartbeatX = this.getTimeSinceHeartbeat(rowX.timestamp) % 60
      const heartbeatY = this.getTimeSinceHeartbeat(rowY.timestamp) % 60

      if (heartbeatX < heartbeatY) { return -1 }
      if (heartbeatX > heartbeatY) { return 1 }
      return 0
    },
    getTimeSinceHeartbeat(heartbeat) {
      const differenceMilliseconds = this.currentTimestamp - heartbeat
      const differenceSeconds = Math.floor(differenceMilliseconds / 1000)
      return differenceSeconds
    },
    getNetworkIcon(connection) {
      if (connection === 'ppp0') {
        return 'BarChartIcon'
      }
      if (connection === 'eth0') {
        return 'RadioIcon'
      }
      if (connection === 'wlan0') {
        return 'WifiIcon'
      }
      return 'WifiOffIcon'
    },
    getNetworkText(connection) {
      if (connection === 'ppp0') {
        return this.$t('gsm')
      }
      if (connection === 'eth0') {
        return this.$t('lan')
      }
      if (connection === 'wlan0') {
        return this.$t('wifi')
      }
      return ''
    },
    getTooltipText(connection) {
      if (connection === 'ppp0') {
        return this.$t('lastOnlineViaGSM')
      }
      if (connection === 'eth0') {
        return this.$t('lastOnlineViaLAN')
      }
      if (connection === 'wlan0') {
        return this.$t('lastOnlineViaWifi')
      }
      return this.$t('lastOnlineUnknown')
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>
